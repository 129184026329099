@import "variables";

.onboarding-container {
  .stepper-large-screen {
    min-width: 700px;
    margin: 0;
  }
  .stepper-small-screen {
    background-color: #f8f9fa;
  }

  .onboarding-content-container {
    width: 100%;
    justify-content: center;

    .phoneauth-card {
      max-width: 700px;
      .verification-code-field {
        max-width: 350px;
      }

      .p-dropdown {
        padding: 0px;
      }
      .p-field {
        margin-bottom: 0px;
      }
    }
  }

  .onboarding-toolbar {
    background-color: $backgroundColor;
    width: 100%;
    max-width: 400px;
    margin-top: 10px !important;
    .p-button {
      min-width: 100px;
    }
  }
}
