.homeview-container {
  .homeview-device-card,
  .homeview-info-card {
    display: flex;
    flex-direction: column;
    max-width: 420px;

    table {
      width: 100%;
    }
    .p-card-subtitle {
      text-align: center;
    }

    .device-info-value,
    .homeview-info-value {
      text-align: right;
    }

    .battery-info {
      text-align: center;
    }

    .device-setting-button {
      text-align: center;
    }
    .p-button-icon-only {
      height: 20px;
    }
  }
}
