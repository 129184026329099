@import "variables";

.p-toolbar {
  width: 100%;
  height: fit-content;
  background-color: #ffffff;
  border: none;
  padding: 0;
  h2 {
    margin: 10px 0px 20px 0px;
  }
}

.p-grid {
  margin: 0px !important;
}

.p-radiobutton-box.p-highlight {
  background-color: $primaryColor !important;
  border-color: $primaryColor !important;
  .p-radiobutton-icon {
    background-color: $primaryColor;
    border-color: $primaryColor;
  }
}
