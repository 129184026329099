@import "variables";

.deviceview-container {
  width: 100%;
  div.deviceview-content {
    width: 100%;
    height: inherit;
    display: flex;

    .devicelist {
      height: fit-content;
      max-width: 450px;
      width: 100%;
    }
  }
}

.device-detail {
  max-width: 800px;
  border: none;

  h3 {
    margin-block-start: 0.1em;
    margin-block-end: 0.2em;
    margin-top: 20px;
  }
  .p-divider {
    margin: 3px;
  }

  .device-setting-section {
    // border-left: 1.5px solid $ihGrey;
    .device-setting-group {
      padding: 1em;

      .setting-entry {
        margin: 0.5em 1em;
        .p-radiobutton {
          margin-right: 0.5em;
        }
      }
      .p-field {
        margin: 0px;
      }
    }
  }
}

#update-address-btn {
  width: 100px;
}
