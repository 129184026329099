.userdataview-container {
  .diseases-card {
    min-height: 300px;
    max-width: 580px;
    .p-listbox-item {
      padding: 0.1rem 1.5rem !important;
    }
  }
}

.userdata-card {
  max-width: 580px;
}
.userdata-p {
  margin: 8px 0px;
}
