.contact-list-entry {
  margin: 0.25rem 0.5rem;
  border-radius: 3px;
}

.selected-contact {
  background-color: var(--primary-color);
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.2rem;
  color: var(--primary-color-text);
}
