.login-container {
  justify-content: center;
  text-align: center;

  .login-card {
    max-width: 550px;

    .login-button {
      width: 206.06px;
    }

    .p-card-footer {
      padding: 0;
    }
  }
}
