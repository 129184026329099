.signup-container {
  justify-content: center;
  text-align: center;

  .signup-card {
    max-width: 550px;

    .signup-button {
      width: 206.06px;
    }

    .p-card-footer {
      padding: 0;
    }
    .p-divider {
      padding-top: 1px !important;
    }
  }
}
