@import "variables";
// @import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
@import "custom_theme.css";
@import "primereact/resources/primereact.min.css";
@import "primeicons/primeicons.css";
@import "pagelayout";
@import "login";
@import "signup";
@import "userdata";
@import "devices";
@import "contacts";
@import "components";
@import "onboarding";
@import "forms";
@import "lists";
@import "alarms";
@import "homeview";
@import "email_verification_incomplete";

@import "../components/header/Header";
@import "../components/buttons/Buttons";
@import "../components/templates/Templates.scss";
@import "../components/texts/Texts.scss";
@import "../components/loaders/Loaders.scss";
@import "../components/toast/Toast.scss";

@font-face {
  font-family: "Bebas Neue";
  src: url("../assets/fonts/BebasNeue-Regular.woff") format("truetype");
}

@font-face {
  font-family: "PT Sans";
  src: url("../assets/fonts/PTSans/ptsans_regular_macroman/PTS55F-webfont.woff")
    format("woff");
}

@font-face {
  font-family: "PT Sans Bold";
  src: url("../assets/fonts/PTSans/ptsans_bold_macroman/PTS75F-webfont.woff")
    format("woff");
}

#root {
  color: $ihGrey;
  background-color: $backgroundColor;
}

.center {
  display: flex;
  width: 100%;
  align-items: center;
}
.column {
  flex-direction: column;
}
.row {
  flex-direction: row;
}
