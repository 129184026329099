@import "variables";

.contactview-container {
  width: 100%;

  .contact-tableview {
    max-width: 1200px;

    .p-row-editor-save,
    .p-row-editor-cancel {
      color: white !important;
      border: 1px solid white;
    }

    .p-row-editor-save {
      background-color: $successColor !important;
    }

    .p-row-editor-cancel {
      background-color: $dangerColor !important;
    }
  }

  .contact-item-view {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .contact-item {
      max-width: 450px;

      .p-card-body {
        padding: 0.8em;

        .p-card-content {
          padding: 0;
        }
      }
    }

    .p-toolbar {
      background-color: #f8f9fa;
    }
  }

  #add-contact-dialog {
    width: 600;
  }
}
