@import "../../styles/variables";

.navlist-button {
  font-size: 120%;
  font-weight: bold;
  color: $ihGrey !important;
  outline: none;
}

.navlist-button.current {
  border: 1px solid $ihGrey;
}
