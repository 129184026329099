html {
  overflow: hidden;
}

.App {
  overflow: auto;
}

html,
body,
#root,
.App {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: "PT Sans", sans-serif !important;
  display: flex;
}

.content-main {
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 100%;
  > * {
    flex: 1 1 auto;
  }

  .content.full {
    // padding: 0 1em; has to be generalized
  }

  .content.small {
    justify-content: center;
    align-items: center;
    padding: 0 0.5em;
  }
}
.ih-footer {
  flex: none;
  justify-content: center;
  background-color: #f8f9fa !important;
  width: 100%;
  margin: 0.5em 0px;
  padding-bottom: 0.5em;
  z-index: 100;
  text-align: center;
  a {
    color: #23282d;
    background-color: transparent;
    text-decoration: none;
  }
  small {
    margin: 0px 20px;
  }
}

a {
  color: #007bff;
  background-color: transparent;
  text-decoration: none;
}
