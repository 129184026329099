.p-progressbar {
  height: 2px;
}

.loader-in-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
