@import "../../styles/variables";
.header {
  flex: none;
  .header-banner {
    background-color: $primaryColor;

    .title {
      font-size: 110%;
      color: #eee;
      margin-left: 0.5em;
    }

    img {
      width: 30px;
      height: inherit;
      margin: 10px 5px 10px 10px;
      padding: 2px;
    }

    .p-button {
      margin: 5px;
    }
  }

  .header-tabmenu {
    .p-tabmenuitem.p-highlight > a {
      background: #f8f9fa !important;
    }
  }

  .p-toolbar {
    width: 100%;
    height: 100%;
    border: none;
  }

  .p-sidebar {
    padding: 0px;

    .p-toolbar {
      padding: 10px !important;
      border-radius: 0px;

      .p-button {
        color: #f8f9fa;
      }
    }

    .p-listbox {
      border-radius: 0px;
    }
  }

  .header-toolbar > .p-toolbar {
    padding: 20px;
    background-color: #f8f9fa;
  }
}
