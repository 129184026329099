$baseMargin: 0px;

// font variables
$IHFont: "PT Sans", sans-serif !important;
$minFontSize: 0.6rem;

// color scheme
$primaryColor: #404f9d;
$successColor: #28a745;
$dangerColor: #dc3545;
$ihGrey: #23282d;
$backgroundColor: #f8f9fa;

:export {
  primaryColor: $primaryColor;
  ihGrey: $ihGrey;
  successColor: $successColor;
  dangerColor: $dangerColor;
  backgroundColor: $backgroundColor;
}
