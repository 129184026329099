.alarmview-container {
  .alarm-tableview {
    max-width: 1200px;
  }

  .alarm-item-view {
    width: 100%;

    .p-toolbar {
      background-color: #f8f9fa;
    }
  }

  .p-highlight {
    background-color: #007bff !important;
    border: 1px solid #007bff;
    color: #ffffff;
  }

  .p-card-body {
    width: inherit;
  }

  .status-icon-success {
    color: green;
  }
  .status-icon-pending {
    color: orange;
  }
  .status-icon-error {
    color: red;
  }
  .status-icon-padding {
    padding: 0px 10px;
  }
}
